import { Button, Card, HorizontalStack, Icon, Text, TextField, VerticalStack } from "@shopify/polaris";
import { ClipboardMinor } from "@shopify/polaris-icons";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import React from "react";
import styled from "styled-components";


import { SmartrrApiStoreAccess, useSmartrrAPITokenModal } from "../store";
import { ApiTokenCardProps } from "../types";
import { formatCreatedDateForDisplay } from "../utils";

const InputFieldWrapper = styled("span")`
  position: relative;
  & .Polaris-Icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 31px;
    z-index: 20;
  }
`;

export const ApiTokenCard = ({ data }: ApiTokenCardProps) => {
  const { addToast } = useToast();
  const { openModal } = useSmartrrAPITokenModal();
  const { deleteToken, copyToken } = SmartrrApiStoreAccess.useActions();
  const { tokenNickName, applicationName, createdDate, updatedDate, smartrrAccessToken } = data;

  const formattedCreatedDate = formatCreatedDateForDisplay(createdDate, "Created");
  const formattedUpdatedDate = ` (${formatCreatedDateForDisplay(updatedDate, "Updated")})`;

  const helpText = `${formattedCreatedDate}${createdDate === updatedDate ? "" : formattedUpdatedDate}`;

  return (
    <Card padding="500">
      <VerticalStack gap="200">
        <HorizontalStack align="space-between">
          <Text as="h2" variant="headingMd">
            {tokenNickName}
          </Text>
          <HorizontalStack gap="5">
            <Button destructive plain onClick={() => deleteToken(data.id)} accessibilityLabel="Delete token">
              Delete token
            </Button>
            <Button plain onClick={() => openModal({ existingTokenData: data })} accessibilityLabel="Edit token">
              Edit token
            </Button>
          </HorizontalStack>
        </HorizontalStack>
        <Text as="h3" fontWeight="medium" variant="headingXs">
          {applicationName}
        </Text>
        <InputFieldWrapper>
          <TextField
            label="API token"
            autoComplete="off"
            type="password"
            helpText={helpText}
            value={smartrrAccessToken}
            disabled
          />
          <span onClick={() => copyToken(data.smartrrAccessToken, addToast)}>
            <Icon source={ClipboardMinor} />
          </span>
        </InputFieldWrapper>
      </VerticalStack>
    </Card>
  );
};
