import { Stack } from "@mui/material";
import { Badge, Button, Checkbox, FormLayout, Modal, Text, TextField } from "@shopify/polaris";
import React, { useCallback, useState } from "react";

import { streamRechargeCustomersToDb } from "@vendor-app/app/_state/actionCreators/initialize";
import { useIsSuperUserSelector } from "@vendor-app/app/_state/reducers/auth";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { useShopSubscriptionGatewayEnabled } from "@vendor-app/utils/useShopSubscriptionGatewayEnabled";

import { IntegrationBrands, IntegrationLogo } from "./integrationLogos";
import { AccountStatus, CardInner, ManualIntegrationCard, ModalWrapper } from "./styles";
import { IntegrationSectionProps } from "./types";

export function ReCharge({ value, loading, onChange, onDelete, onSave }: IntegrationSectionProps): JSX.Element {
  const dispatch = useSmartrrVendorDispatch();
  const isSuperUser = useIsSuperUserSelector();
  const activeOrg = useActiveOrganizationSelector();
  const {
    purchaseStates: { isStreaming },
  } = useSmartrrVendorSelector(state => state);

  const [reChargeForce, setReChargeForce] = useState(false);
  const [reChargeActive, setReChargeActive] = useState(false);

  const { loading: legacySubscriptionGatewayEnabledLoading, enabled: legacySubscriptionGatewayEnabled } =
    useShopSubscriptionGatewayEnabled();

  const onStreamClick = useCallback(() => {
    if (activeOrg) {
      dispatch(streamRechargeCustomersToDb(activeOrg.id, reChargeForce, reChargeActive));
    }
  }, [activeOrg, reChargeForce, reChargeActive]);

  const openConnectToStripe = useCallback(() => {
    window.open(`${window.location.protocol}//${activeOrg?.myShopifyDomain!}/admin/payments/legacy_subscription`);
  }, [activeOrg]);

  const [active, setActive] = useState(true);
  const modalChange = useCallback(() => setActive(!active), [active]);

  return (
    <ManualIntegrationCard>
      <ModalWrapper>
        <Modal
          open={!active}
          onClose={modalChange}
          title="Add ReCharge API key"
          primaryAction={{
            content: "Confirm",
            loading,
            onAction() {
              onSave();
              modalChange();
            },
          }}
          secondaryActions={[
            {
              content: value ? "Delete" : "Cancel",

              loading,
              onAction: value
                ? () => {
                    onDelete();
                    modalChange();
                  }
                : modalChange,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  autoComplete="off"
                  label="API key"
                  value={value?.apiKey}
                  onChange={val => onChange(val)}
                />
              </FormLayout.Group>
              {isSuperUser ? (
                <Stack
                  spacing="10px"
                  sx={{
                    borderTop: "var(--p-border-width-1) solid var(--p-color-border-subdued)",
                    paddingTop: "20px",
                    "& button": {
                      width: "max-content",
                    },
                  }}
                >
                  <Button
                    disabled={legacySubscriptionGatewayEnabled}
                    loading={legacySubscriptionGatewayEnabledLoading}
                    onClick={openConnectToStripe}
                  >
                    {legacySubscriptionGatewayEnabled
                      ? "Stripe connected"
                      : "Connect Stripe as a secondary gateway"}
                  </Button>
                  <Button loading={isStreaming} onClick={onStreamClick}>
                    Migrate ReCharge subscriptions
                  </Button>

                  <Checkbox label="Force" onChange={setReChargeForce} checked={reChargeForce} />
                  <Checkbox label="Active customers only" onChange={setReChargeActive} checked={reChargeActive} />
                </Stack>
              ) : (
                "Enter your ReCharge API key to enable importing subscriptions from ReCharge."
              )}
            </FormLayout>
          </Modal.Section>
        </Modal>
      </ModalWrapper>
      <CardInner>
        <Stack direction="column" spacing="10px">
          <Stack direction="row" justifyContent="space-between" alignItems="self-start">
            <Stack direction="row" spacing="10px">
              <div className="integration-logo">
                <IntegrationLogo brand={IntegrationBrands.Recharge} />
              </div>
              <Stack direction="column" spacing="3px">
                <Text variant="headingMd" as="h2">
                  Recharge
                </Text>
                {value ? (
                  <AccountStatus>Account connected</AccountStatus>
                ) : (
                  <AccountStatus>No account connected</AccountStatus>
                )}
              </Stack>
            </Stack>
            {value ? (
              <Button onClick={modalChange}>Disconnect</Button>
            ) : (
              <Button primary onClick={modalChange}>
                Connect
              </Button>
            )}
          </Stack>
          <div className="integration-description">
            Connect to migrate your subscriptions from Recharge and to connect Stripe as a secondary gateway.
          </div>
          <div className="integration-attributes">
            <Badge>Migrations</Badge>
          </div>
          <div className="integration-more-info">
            <a
              href="https://help.smartrr.com/docs/support/integrations/recharge-x-smartrr-integration"
              target="_blank"
              rel="noreferrer"
            >
              More Info
            </a>
          </div>
        </Stack>
      </CardInner>
    </ManualIntegrationCard>
  );
}
