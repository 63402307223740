import { ComplexAction, Modal, Text, TextField } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import {
  ICreateSmartrrApiToken,
  ResourceAccessLevel,
  ResourceScopeArr,
  maxTokenScopeSettings,
  smartrrApiTokenFormDefaultValues,
  smartrrApiTokenFormValidationSchema,
} from "@smartrr/shared/entities/SmartrrApiToken";
import { omit } from "lodash";
import { useCallback, useMemo } from "react";
import { Divider } from "rsuite";
import styled from "styled-components";

import { ResourceSelectionItem } from "./libs/components/ResourceSelectionItem";
import { SmartrrApiStoreAccess } from "./libs/store";
import { ISmartrrApiTokenModalProps } from "./libs/types";
import { useTypedForm } from "../../../../_sharedComponents/TypedForm/useTypedForm";

const ResourceAccessSelections = styled.div`
  .resource-access-header {
    font-variant-caps: all-small-caps;
  }
  .resource-access-level-selections {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;

    .resource-access-level-selection {
      padding-bottom: 9px;
      border-bottom: 1px solid var(--rs-divider-border);
      .resource-dropdown-wrapper {
        width: 258px;
      }
    }
  }
`;

export function SmartrrApiTokenModal({ onClose, existingToken }: ISmartrrApiTokenModalProps): JSX.Element {
  const { createToken, updateToken } = SmartrrApiStoreAccess.useActions();

  const initialValues: ICreateSmartrrApiToken = useMemo(
    () => ({ ...smartrrApiTokenFormDefaultValues, ...omit(existingToken, ["smartrrAccessToken"]) }),
    [existingToken]
  );

  const onSubmit = useCallback(
    (formValues: ICreateSmartrrApiToken) => {
      if (existingToken) {
        updateToken(existingToken.id, formValues);
      } else {
        createToken(formValues);
      }
      onClose();
    },
    [existingToken, onClose]
  );

  const { useField, useSubForm, isValid, submitForm } = useTypedForm<ICreateSmartrrApiToken>({
    validationSchema: smartrrApiTokenFormValidationSchema,
    initialValues,
    onSubmit,
  });
  const [useScopeSettingsField] = useSubForm("scopeSettings");

  const { Input: TokenNickname } = useField("tokenNickName");
  const { Input: ApplicationName } = useField("applicationName");

  const primaryAction: ComplexAction = useMemo(
    () => ({
      disabled: !isValid,
      content: existingToken ? "Update" : "Create",
      onAction: submitForm,
    }),
    [isValid, existingToken, submitForm]
  );

  const secondaryAction = useMemo(
    () => [
      {
        content: "Cancel",
        onAction: onClose,
      },
    ],
    [onClose]
  );

  const filteredResources = useMemo(
    () => ResourceScopeArr.filter(scope => maxTokenScopeSettings[scope] !== ResourceAccessLevel.NONE),
    [ResourceScopeArr]
  );

  return (
    <Modal
      open
      title={`${existingToken ? "Edit" : "Create"} API Key`}
      onClose={onClose}
      primaryAction={primaryAction}
      secondaryActions={secondaryAction}
    >
      <Modal.Section>
        <Box direction="column" alignItems="stretch">
          <TokenNickname type="text" usePolaris label="Token Nickname" placeholder="Example: Klaviyo Token" />
        </Box>
        <Box pt={1.25} direction="column" alignItems="stretch">
          <ApplicationName type="text" usePolaris label="Application Name" placeholder="Example: Klaviyo" />
        </Box>
        <Box pt={1.25} direction="column" alignItems="stretch">
          <TextField autoComplete="off" label="Access Token" disabled value={existingToken?.smartrrAccessToken} />
        </Box>
        <Box pt={1.25} direction="column" alignItems="stretch">
          <TextField autoComplete="off" label="Client ID" disabled value={existingToken?.clientId} />
        </Box>
        <Divider />
        <ResourceAccessSelections>
          <span className="resource-access-header">
            <Text as="h3" variant="headingMd">
              Access Scopes
            </Text>
          </span>
          <div className="resource-access-level-selections">
            {filteredResources.map(scope => (
              <ResourceSelectionItem key={scope} useScopeSettingsField={useScopeSettingsField} scope={scope} />
            ))}
          </div>
        </ResourceAccessSelections>
      </Modal.Section>
    </Modal>
  );
}
