import { Stack } from "@mui/material";
import { Badge, Button, Text } from "@shopify/polaris";
import React, { useCallback } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import attentiveLogo from "./assets/attentive.png";
import { openAttentiveWindow } from "./openAttentiveWindow";
import { AccountStatus, CardInner, ManualIntegrationCard } from "../styles";
import { IntegrationSectionProps } from "../types";

export function Attentive({ onChange, value, onDelete }: IntegrationSectionProps): JSX.Element {
  const { addToast } = useToast();

  const onOpenAttentiveConnection = useCallback(async () => {
    const res = await typedFrontendVendorApi.getReq("/integrations/attentive/getHash");
    if (res.type == "error") {
      return;
    }

    openAttentiveWindow(res.body.state)
      .then(() => {
        onChange("");
        addToast("Attentive connected successfully.");
      })
      .catch(error => {
        addToast("Error connecting to Attentive.");
      });
  }, [addToast, onChange]);

  return (
    <ManualIntegrationCard>
      <CardInner>
        <Stack direction="column" spacing="10px">
          <Stack direction="row" justifyContent="space-between" alignItems="self-start">
            <Stack direction="row" spacing="10px">
              <div className="integration-logo">
                <img src={attentiveLogo} alt="" width={38} />
              </div>
              <Stack direction="column" spacing="3px">
                <Text variant="headingMd" as="h2">
                  Attentive
                </Text>
                {value ? (
                  <AccountStatus>Account connected</AccountStatus>
                ) : (
                  <AccountStatus>No account connected</AccountStatus>
                )}
              </Stack>
            </Stack>
            {value ? (
              <Button destructive={true} primary onClick={onDelete}>
                Disconnect
              </Button>
            ) : (
              <Button primary onClick={onOpenAttentiveConnection}>
                Connect
              </Button>
            )}
          </Stack>
          <div className="integration-description">
            Connect to set up Journeys in Attentive using Smartrr subscription events.
          </div>
          <div className="integration-attributes">
            <Badge>Emails</Badge>
            <Badge>SMS</Badge>
          </div>
          <div className="integration-more-info">
            <a
              href="https://help.attentivemobile.com/hc/en-us/articles/360051937831-What-are-journeys-"
              target="_blank"
              rel="noreferrer"
            >
              More Info
            </a>
          </div>
        </Stack>
      </CardInner>
    </ManualIntegrationCard>
  );
}
