import { capitalize } from "lodash";
import * as yup from "yup";

import { ICreateEntity, ISharedEntityFields } from "./shared/SharedEntityFields";
import { RequireNilFields } from "../utils/RequireNilFields";

export interface ISmartrrApiToken extends ISharedEntityFields {
  tokenNickName: string;
  applicationName: string; // Identifies application for which token was issued. Not null-able because form can't work with nullable fields.
  smartrrAccessToken: string;
  clientId: string;
  scopeSettings: ScopeSettings;
}

export type ICreateSmartrrApiToken = Omit<ICreateEntity<ISmartrrApiToken>, "clientId" | "smartrrAccessToken">;

export enum ResourceAccessLevel {
  NONE = 0,
  READ = 1,
  READ_AND_WRITE = 2,
  AUTH_ONLY = 3,
  SUPER_USER_ONLY = 4,
}

type ResourceAccessLevelKey = keyof typeof ResourceAccessLevel;

export enum ResourceScope {
  ACCOUNT_PLAN = "ACCOUNT_PLAN",
  SELLING_PLAN = "SELLING_PLAN",
  SUBSCRIPTION = "SUBSCRIPTION",
  BILL = "BILL",
  PRODUCT = "PRODUCT",
  CUSTOMER = "CUSTOMER",
  ORDER = "ORDER",
  ANALYTICS = "ANALYTICS",
  CUSTOMER_PORTAL_THEME = "CUSTOMER_PORTAL_THEME",
  STORE_DATA = "STORE_DATA",
  EMAIL_CONFIG = "EMAIL_CONFIG",
  SMS_CONFIG = "SMS_CONFIG",
  INTEGRATIONS_CONFIG = "INTEGRATIONS_CONFIG",
  WEBHOOK = "WEBHOOK",
  SMARTRR_SCRIPTS = "SMARTRR_SCRIPTS",
  REWARD_PROGRAM = "REWARD_PROGRAM",
}

export const ResourceScopeArr = Object.values(ResourceScope);

export type ScopeSettings = { [key in keyof typeof ResourceScope]?: ResourceAccessLevel };

export function getAccessLevelsUpTo(accessLevel: ResourceAccessLevel): ResourceAccessLevel[] {
  // enums get odd, idk why I need to convert/case
  return Object.values(ResourceAccessLevel)
    .map(v => Number(v))
    .filter(v => !isNaN(v) && v <= accessLevel);
}

export function resourceAccessLevelToString(accessLevel: ResourceAccessLevel): ResourceAccessLevelKey {
  const keys = Object.keys(ResourceAccessLevel) as ResourceAccessLevelKey[];

  let resultKey: ResourceAccessLevelKey = "NONE";
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value: number = ResourceAccessLevel[key];
    if (value === accessLevel) {
      resultKey = key;
      break;
    }
  }

  return resultKey;
}

export function constantStringToView(constantStr: string): string {
  return constantStr
    .split("_")
    .map(v => capitalize(v.toLowerCase()))
    .join(" ");
}

export function checkIfMeetsRequiredScope(scope: ScopeSettings, requiredScope: ScopeSettings) {
  return Object.keys(requiredScope).reduce((accum: string[], requiredScopeKey) => {
    const castedRequiredScopeKey = requiredScopeKey as keyof typeof ResourceScope;
    const requiredAccessLevel = requiredScope[castedRequiredScopeKey] || ResourceAccessLevel.NONE;
    const actualAccessLevel = scope[castedRequiredScopeKey] || ResourceAccessLevel.NONE;

    if (actualAccessLevel < requiredAccessLevel) {
      const requiredAccessLevelString = resourceAccessLevelToString(requiredAccessLevel);
      const actualAccessLevelString = resourceAccessLevelToString(actualAccessLevel);
      accum.push(
        `${castedRequiredScopeKey}: ${actualAccessLevelString} access level granted, ${requiredAccessLevelString} is required`
      );
    }

    return accum;
  }, []);
}

export const defaultAvailableScopeSettings: RequireNilFields<ScopeSettings> = {
  [ResourceScope.ACCOUNT_PLAN]: ResourceAccessLevel.READ,
  [ResourceScope.SELLING_PLAN]: ResourceAccessLevel.READ,
  [ResourceScope.SUBSCRIPTION]: ResourceAccessLevel.READ,
  [ResourceScope.BILL]: ResourceAccessLevel.READ,
  [ResourceScope.PRODUCT]: ResourceAccessLevel.READ,
  [ResourceScope.CUSTOMER]: ResourceAccessLevel.READ,
  [ResourceScope.STORE_DATA]: ResourceAccessLevel.READ,
  [ResourceScope.ORDER]: ResourceAccessLevel.READ,
  [ResourceScope.SMARTRR_SCRIPTS]: ResourceAccessLevel.READ,
  [ResourceScope.ANALYTICS]: ResourceAccessLevel.NONE,
  [ResourceScope.CUSTOMER_PORTAL_THEME]: ResourceAccessLevel.NONE,
  [ResourceScope.EMAIL_CONFIG]: ResourceAccessLevel.NONE,
  [ResourceScope.SMS_CONFIG]: ResourceAccessLevel.NONE,
  [ResourceScope.INTEGRATIONS_CONFIG]: ResourceAccessLevel.NONE,
  [ResourceScope.WEBHOOK]: ResourceAccessLevel.READ,
  [ResourceScope.REWARD_PROGRAM]: ResourceAccessLevel.READ,
};

export const gorgiasScopeSettings: RequireNilFields<ScopeSettings> = {
  [ResourceScope.ACCOUNT_PLAN]: ResourceAccessLevel.READ,
  [ResourceScope.SELLING_PLAN]: ResourceAccessLevel.READ,
  [ResourceScope.SUBSCRIPTION]: ResourceAccessLevel.READ_AND_WRITE,
  [ResourceScope.BILL]: ResourceAccessLevel.READ,
  [ResourceScope.PRODUCT]: ResourceAccessLevel.READ,
  [ResourceScope.CUSTOMER]: ResourceAccessLevel.READ,
  [ResourceScope.STORE_DATA]: ResourceAccessLevel.READ,
  [ResourceScope.ORDER]: ResourceAccessLevel.READ,
  [ResourceScope.SMARTRR_SCRIPTS]: ResourceAccessLevel.READ,
  [ResourceScope.ANALYTICS]: ResourceAccessLevel.NONE,
  [ResourceScope.CUSTOMER_PORTAL_THEME]: ResourceAccessLevel.NONE,
  [ResourceScope.EMAIL_CONFIG]: ResourceAccessLevel.NONE,
  [ResourceScope.SMS_CONFIG]: ResourceAccessLevel.NONE,
  [ResourceScope.INTEGRATIONS_CONFIG]: ResourceAccessLevel.NONE,
  [ResourceScope.WEBHOOK]: ResourceAccessLevel.READ,
  [ResourceScope.REWARD_PROGRAM]: ResourceAccessLevel.READ,
};

export const maxTokenScopeSettings: RequireNilFields<ScopeSettings> = {
  [ResourceScope.ACCOUNT_PLAN]: ResourceAccessLevel.READ,
  [ResourceScope.SELLING_PLAN]: ResourceAccessLevel.READ_AND_WRITE,
  [ResourceScope.SUBSCRIPTION]: ResourceAccessLevel.READ_AND_WRITE,
  [ResourceScope.BILL]: ResourceAccessLevel.READ_AND_WRITE,
  [ResourceScope.PRODUCT]: ResourceAccessLevel.READ,
  [ResourceScope.CUSTOMER]: ResourceAccessLevel.READ_AND_WRITE,
  [ResourceScope.STORE_DATA]: ResourceAccessLevel.READ,
  [ResourceScope.ORDER]: ResourceAccessLevel.READ,
  [ResourceScope.SMARTRR_SCRIPTS]: ResourceAccessLevel.READ_AND_WRITE,
  [ResourceScope.ANALYTICS]: ResourceAccessLevel.NONE,
  [ResourceScope.CUSTOMER_PORTAL_THEME]: ResourceAccessLevel.READ_AND_WRITE,
  [ResourceScope.EMAIL_CONFIG]: ResourceAccessLevel.NONE,
  [ResourceScope.SMS_CONFIG]: ResourceAccessLevel.NONE,
  [ResourceScope.INTEGRATIONS_CONFIG]: ResourceAccessLevel.READ_AND_WRITE,
  [ResourceScope.WEBHOOK]: ResourceAccessLevel.READ_AND_WRITE,
  [ResourceScope.REWARD_PROGRAM]: ResourceAccessLevel.READ_AND_WRITE,
};

export const smartrrApiTokenFormDefaultValues: ICreateSmartrrApiToken = {
  tokenNickName: "",
  applicationName: "",
  scopeSettings: defaultAvailableScopeSettings,
};

export const smartrrApiTokenFormValidationSchema = yup.object().shape({
  tokenNickName: yup.string().required(),
  applicationName: yup.string().required(),
  scopeSettings: yup
    .object()
    .shape({
      [ResourceScope.ACCOUNT_PLAN]: yup
        .number()
        .lessThan(maxTokenScopeSettings[ResourceScope.ACCOUNT_PLAN] + 1)
        .required(),
      [ResourceScope.SELLING_PLAN]: yup
        .number()
        .lessThan(maxTokenScopeSettings[ResourceScope.SELLING_PLAN] + 1)
        .required(),
      [ResourceScope.SUBSCRIPTION]: yup
        .number()
        .lessThan(maxTokenScopeSettings[ResourceScope.SUBSCRIPTION] + 1)
        .required(),
      [ResourceScope.BILL]: yup
        .number()
        .lessThan(maxTokenScopeSettings[ResourceScope.BILL] + 1)
        .required(),
      [ResourceScope.PRODUCT]: yup
        .number()
        .lessThan(maxTokenScopeSettings[ResourceScope.PRODUCT] + 1)
        .required(),
      [ResourceScope.CUSTOMER]: yup
        .number()
        .lessThan(maxTokenScopeSettings[ResourceScope.CUSTOMER] + 1)
        .required(),
      [ResourceScope.STORE_DATA]: yup
        .number()
        .lessThan(maxTokenScopeSettings[ResourceScope.STORE_DATA] + 1)
        .required(),
      [ResourceScope.ORDER]: yup
        .number()
        .lessThan(maxTokenScopeSettings[ResourceScope.ORDER] + 1)
        .required(),
      [ResourceScope.ANALYTICS]: yup
        .number()
        .lessThan(maxTokenScopeSettings[ResourceScope.ANALYTICS] + 1)
        .required(),
      [ResourceScope.CUSTOMER_PORTAL_THEME]: yup
        .number()
        .lessThan(maxTokenScopeSettings[ResourceScope.CUSTOMER_PORTAL_THEME] + 1)
        .required(),
      [ResourceScope.EMAIL_CONFIG]: yup
        .number()
        .lessThan(maxTokenScopeSettings[ResourceScope.EMAIL_CONFIG] + 1)
        .required(),
      [ResourceScope.SMS_CONFIG]: yup
        .number()
        .lessThan(maxTokenScopeSettings[ResourceScope.SMS_CONFIG] + 1)
        .required(),
      [ResourceScope.INTEGRATIONS_CONFIG]: yup
        .number()
        .lessThan(maxTokenScopeSettings[ResourceScope.INTEGRATIONS_CONFIG] + 1)
        .required(),
      [ResourceScope.WEBHOOK]: yup
        .number()
        .lessThan(maxTokenScopeSettings[ResourceScope.WEBHOOK] + 1)
        .required(),
    })
    .required(),
});

export const authedVendorScopeLevel: RequireNilFields<ScopeSettings> = {
  [ResourceScope.ACCOUNT_PLAN]: ResourceAccessLevel.READ,
  [ResourceScope.SELLING_PLAN]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.SUBSCRIPTION]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.BILL]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.PRODUCT]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.CUSTOMER]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.STORE_DATA]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.ORDER]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.SMARTRR_SCRIPTS]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.ANALYTICS]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.CUSTOMER_PORTAL_THEME]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.EMAIL_CONFIG]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.SMS_CONFIG]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.INTEGRATIONS_CONFIG]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.WEBHOOK]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.REWARD_PROGRAM]: ResourceAccessLevel.AUTH_ONLY,
};

export const authedVendorAdminScopeLevel: RequireNilFields<ScopeSettings> = {
  [ResourceScope.ACCOUNT_PLAN]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.SELLING_PLAN]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.SUBSCRIPTION]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.BILL]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.PRODUCT]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.CUSTOMER]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.STORE_DATA]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.ORDER]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.SMARTRR_SCRIPTS]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.ANALYTICS]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.CUSTOMER_PORTAL_THEME]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.EMAIL_CONFIG]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.SMS_CONFIG]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.INTEGRATIONS_CONFIG]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.WEBHOOK]: ResourceAccessLevel.AUTH_ONLY,
  [ResourceScope.REWARD_PROGRAM]: ResourceAccessLevel.AUTH_ONLY,
};

export const authedVendorSuperUserScopeLevel: RequireNilFields<ScopeSettings> = {
  [ResourceScope.ACCOUNT_PLAN]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.SELLING_PLAN]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.SUBSCRIPTION]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.BILL]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.PRODUCT]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.CUSTOMER]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.STORE_DATA]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.ORDER]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.SMARTRR_SCRIPTS]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.ANALYTICS]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.CUSTOMER_PORTAL_THEME]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.EMAIL_CONFIG]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.SMS_CONFIG]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.INTEGRATIONS_CONFIG]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.WEBHOOK]: ResourceAccessLevel.SUPER_USER_ONLY,
  [ResourceScope.REWARD_PROGRAM]: ResourceAccessLevel.SUPER_USER_ONLY,
};
