import { Stack } from "@mui/material";
import { Badge, Button, Text } from "@shopify/polaris";
import React from "react";

import { IntegrationBrands, IntegrationLogo } from "./integrationLogos";
import { AccountStatus, CardInner, ManualIntegrationCard } from "./styles";
import { IntegrationSectionProps } from "./types";

export function ShopifyFlow({ onDelete, onSave, value }: IntegrationSectionProps): JSX.Element {
  return (
    <ManualIntegrationCard>
      <CardInner>
        <CardInner>
          <Stack direction="column" spacing="10px">
            <Stack direction="row" justifyContent="space-between" alignItems="self-start">
              <Stack direction="row" spacing="10px">
                <div className="integration-logo">
                  <IntegrationLogo brand={IntegrationBrands.ShopifyFlow} />
                </div>
                <Stack direction="column" spacing="3px">
                  <Text variant="headingMd" as="h2">
                    Shopify Flow
                  </Text>
                  {value ? <AccountStatus>Enabled</AccountStatus> : <AccountStatus>Not enabled</AccountStatus>}
                </Stack>
              </Stack>
              {value ? (
                <Button onClick={onDelete}>Disconnect</Button>
              ) : (
                <Button primary onClick={onSave}>
                  Connect
                </Button>
              )}
            </Stack>
            <div className="integration-description">
              Connect to enable Shopify Flow triggers and actions from Smartrr and automate tasks and processes
              within your store.
            </div>
            <div className="integration-attributes">
              <Badge>Workflow</Badge>
            </div>
            <div className="integration-more-info">
              <a target="_blank" href="https://help.shopify.com/en/manual/shopify-flow" rel="noreferrer">
                More Info
              </a>
            </div>
          </Stack>
        </CardInner>
      </CardInner>
    </ManualIntegrationCard>
  );
}
