import { Stack } from "@mui/material";
import { Badge, Button, FormLayout, Link, Modal, Text, TextField } from "@shopify/polaris";
import React, { useCallback, useState } from "react";

import { IntegrationBrands, IntegrationLogo } from "./integrationLogos";
import { AccountStatus, CardInner, ManualIntegrationCard, ModalWrapper } from "./styles";
import { IntegrationSectionProps } from "./types";

export function Klaviyo({
  onDelete,
  onChange,
  onSave,
  loading,
  value,
  savedValue,
}: IntegrationSectionProps): JSX.Element {
  const [active, setActive] = useState(true);
  const modalChange = useCallback(() => setActive(!active), [active]);

  return (
    <ManualIntegrationCard>
      <ModalWrapper>
        <Modal
          open={!active}
          onClose={modalChange}
          title="Add Klaviyo API key"
          primaryAction={{
            content: "Confirm",
            loading,
            disabled: !value?.secretApiKey || !value.apiKey,
            onAction() {
              onSave();
              modalChange();
            },
          }}
          secondaryActions={[
            {
              content: savedValue?.apiKey && savedValue.secretApiKey ? "Delete" : "Cancel",

              loading,
              onAction:
                savedValue?.apiKey && savedValue.secretApiKey
                  ? () => {
                      onDelete();
                      modalChange();
                    }
                  : modalChange,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                autoComplete="off"
                label="Public API key"
                value={value?.apiKey}
                onChange={apiKey => onChange(apiKey, value?.secretApiKey)}
              />
              <TextField
                autoComplete="off"
                label="Private API key"
                value={value?.secretApiKey}
                onChange={secretApiKey => onChange(value?.apiKey ?? "", secretApiKey)}
                type="password"
                focused={!!(savedValue?.apiKey && !savedValue.secretApiKey)}
              />
              <Link
                url="https://help.klaviyo.com/hc/en-us/articles/115005062267"
                external
                accessibilityLabel="Klaviyo how to manage your account's API keys link"
                removeUnderline
              >
                Where do I find my Klaviyo API keys?
              </Link>
            </FormLayout>
          </Modal.Section>
        </Modal>
      </ModalWrapper>
      <CardInner>
        <CardInner>
          <Stack direction="column" spacing="10px">
            <Stack direction="row" justifyContent="space-between" alignItems="self-start">
              <Stack direction="row" spacing="10px">
                <div className="integration-logo">
                  <IntegrationLogo brand={IntegrationBrands.Klaviyo} />
                </div>
                <Stack direction="column" spacing="3px">
                  <Text variant="headingMd" as="h2">
                    Klaviyo
                  </Text>
                  {savedValue?.apiKey && savedValue.secretApiKey ? (
                    <AccountStatus>Account connected</AccountStatus>
                  ) : (
                    <AccountStatus>No account connected</AccountStatus>
                  )}
                </Stack>
              </Stack>
              {savedValue?.apiKey && savedValue.secretApiKey ? (
                <Button onClick={modalChange}>Disconnect</Button>
              ) : (
                <Button primary onClick={modalChange}>
                  {savedValue?.apiKey && !savedValue.secretApiKey ? "Update" : "Connect"}
                </Button>
              )}
            </Stack>
            <div className="integration-description">
              Connect to sync subscription event data to Klaviyo to set up email and SMS flows and notifications.
            </div>
            <div className="integration-attributes">
              <Badge>Emails</Badge>
              <Badge>SMS</Badge>
            </div>
            <div className="integration-more-info">
              <a
                target="_blank"
                href="https://help.smartrr.com/docs/support/integrations/klaviyo-x-smartrr-integration"
                rel="noreferrer"
              >
                More Info
              </a>
            </div>
          </Stack>
        </CardInner>
      </CardInner>
    </ManualIntegrationCard>
  );
}
