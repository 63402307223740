import { IWebhook, IWebhookCreate } from "@smartrr/shared/entities/Webhook";

import { SmartrrThunkAction } from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const loadWebhooks =
  (): SmartrrThunkAction<"LOADING_WEBHOOKS" | "ERROR_LOADING_WEBHOOKS" | "LOADED_WEBHOOKS"> => dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.getReq("/webhook", {}),
      () =>
        dispatch({
          type: "LOADING_WEBHOOKS",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_LOADING_WEBHOOKS",
          payload: {
            errorMessage: failure.message,
          },
        }),
      webhooks =>
        dispatch({
          type: "LOADED_WEBHOOKS",
          payload: {
            webhooks: webhooks as IWebhook[],
          },
        })
    );

export const createWebhook =
  (
    webhook: IWebhookCreate
  ): SmartrrThunkAction<"CREATING_WEBHOOK" | "ERROR_CREATING_WEBHOOK" | "CREATED_WEBHOOK"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq("/webhook", {
        reqBody: webhook,
      }),
      () =>
        dispatch({
          type: "CREATING_WEBHOOK",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_CREATING_WEBHOOK",
          payload: {
            errorMessage: failure.message,
          },
        }),
      webhook =>
        dispatch({
          type: "CREATED_WEBHOOK",
          payload: {
            webhook: webhook as IWebhook,
          },
        })
    );

export const updateWebhook =
  (
    webhookId: string,
    webhook: IWebhookCreate
  ): SmartrrThunkAction<"UPDATING_WEBHOOK" | "ERROR_UPDATING_WEBHOOK" | "UPDATED_WEBHOOK"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/webhook/:webhookId", {
        params: {
          webhookId,
        },
        reqBody: webhook,
      }),
      () =>
        dispatch({
          type: "UPDATING_WEBHOOK",
          payload: { webhookId },
        }),
      failure =>
        dispatch({
          type: "ERROR_UPDATING_WEBHOOK",
          payload: {
            errorMessage: failure.message,
          },
        }),
      webhook =>
        dispatch({
          type: "UPDATED_WEBHOOK",
          payload: {
            webhook: webhook as IWebhook,
          },
        })
    );

export const deleteWebhook =
  (webhookId: string): SmartrrThunkAction<"DELETING_WEBHOOK" | "ERROR_DELETING_WEBHOOK" | "DELETED_WEBHOOK"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.deleteReq("/webhook/:webhookId", {
        params: {
          webhookId,
        },
      }),
      () =>
        dispatch({
          type: "DELETING_WEBHOOK",
          payload: { webhookId },
        }),
      failure =>
        dispatch({
          type: "ERROR_DELETING_WEBHOOK",
          payload: {
            errorMessage: failure.message,
          },
        }),
      () =>
        dispatch({
          type: "DELETED_WEBHOOK",
          payload: {
            webhookId,
          },
        })
    );
