import { Box } from "@smartrr/shared/components/primitives";
import {
  constantStringToView,
  getAccessLevelsUpTo,
  maxTokenScopeSettings,
  resourceAccessLevelToString,
} from "@smartrr/shared/entities/SmartrrApiToken";
import { useMemo } from "react";

import { ResourceSelectionItemProps } from "../types";

export const ResourceSelectionItem = ({ scope, useScopeSettingsField }: ResourceSelectionItemProps) => {
  const maxAccessLevelForScope = maxTokenScopeSettings[scope];

  const {
    Dropdown: ResourceScopeDropdown,
    Label: ResourceScopeLabel,
    ValidationError: ResourceScopeValidationError,
  } = useScopeSettingsField(scope);
  const resourceScopeString = constantStringToView(scope);

  const options = useMemo(
    () =>
      getAccessLevelsUpTo(maxAccessLevelForScope).map(accessLevel => ({
        value: accessLevel,
        label: constantStringToView(resourceAccessLevelToString(accessLevel)),
      })),
    [maxAccessLevelForScope]
  );

  return (
    <div key={resourceScopeString} className="resource-access-level-selection">
      <Box justifyContent="space-between" alignItems="center">
        <ResourceScopeLabel>{`${resourceScopeString}:`}</ResourceScopeLabel>
        <div className="resource-dropdown-wrapper">
          <ResourceScopeDropdown usePolaris description={resourceScopeString} options={options} />
        </div>
      </Box>
      <ResourceScopeValidationError />
    </div>
  );
};
