import { appPrefixedHost, vendorApiRoutePrefix } from "@smartrr/shared/constants";
import config from "@vendor-app/config";

export const openAttentiveWindow = async (formattedState: any) =>
  new Promise<"success">((resolve, reject) => {
    const query = new URLSearchParams({
      client_id: config.attentive.clientId,
      redirect_uri: `https://${appPrefixedHost}${vendorApiRoutePrefix}/integrations/attentive`,
      state: formattedState,
    }).toString();

    const oauthWindow = window.open(
      `https://ui.attentivemobile.com/integrations/oauth-install?${query}`,
      "_blank",
      "toolbar=no, menubar=no"
    );
    if (oauthWindow) {
      const messageEventListener = (event: MessageEvent<"success">) => {
        if (["success", "failure"].includes(event.data)) {
          if (event.data === "success") {
            resolve(event.data);
          } else if (event.data === "failure") {
            reject(event.data);
          }
          oauthWindow.close();
          window.removeEventListener("message", messageEventListener);
        }
      };
      window.addEventListener("message", messageEventListener);
    }
  });
