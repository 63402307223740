import { Stack } from "@mui/material";
import { Badge, Button, FormLayout, Modal, Text, TextField } from "@shopify/polaris";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  IntegrationBrands,
  IntegrationLogo,
} from "@vendor-app/app/AdminRoute/AdminIntegrationsRoute/integrations/integrationLogos";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { useCurrentOrganization } from "@vendor-app/utils/useCurrentOrganization";
import React, { useCallback, useState } from "react";

import { AccountStatus, CardInner, ManualIntegrationCard, ModalWrapper } from "../styles";
import { IntegrationSectionProps } from "../types";

async function connectClickHandler(
  shop: string,
  subdomain: string,
  addToast: (content: string, error?: boolean | undefined) => void
) {
  const res = await typedFrontendVendorApi.getReq("/integrations/gorgias/connect", {
    query: {
      shop,
      subdomain,
    },
  });
  if (res.type === "success") {
    window.location.href = res.body.redirectUri;
  } else {
    addToast("Error connecting to Gorgias, please try again", true);
  }
}

export function GorgiasOAuth2({ onChange, value, onDelete }: IntegrationSectionProps): JSX.Element {
  const { addToast } = useToast();

  const org = useCurrentOrganization();
  const [active, setActive] = useState(true);
  const modalChange = useCallback(() => setActive(!active), [active]);
  const [gorgiasDomain, setGorgiasDomain] = React.useState<string>("");
  return (
    <ManualIntegrationCard>
      <ModalWrapper>
        <Modal
          open={!active}
          onClose={modalChange}
          title="Add Gorgias information"
          primaryAction={{
            content: "Connect and Create Widget",
            onAction: async () => await connectClickHandler(org.myShopifyDomain!, gorgiasDomain, addToast),
            disabled: !gorgiasDomain,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction() {
                modalChange();
              },
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <TextField
                value={gorgiasDomain}
                label={"Gorgias account domain"}
                autoComplete={"off"}
                onChange={subdomain => setGorgiasDomain(subdomain)}
                suffix={".gorgias.com"}
              />
            </FormLayout>
          </Modal.Section>
        </Modal>
      </ModalWrapper>
      <CardInner>
        <Stack direction="column" spacing="10px">
          <Stack direction="row" justifyContent="space-between" alignItems="self-start">
            <Stack direction="row" spacing="10px">
              <div className="integration-logo">
                <IntegrationLogo brand={IntegrationBrands.Gorgias} />
              </div>
              <Stack direction="column" spacing="3px">
                <Text variant="headingMd" as="h2">
                  Gorgias
                </Text>
                <AccountStatus>Check Gorgias for integration status</AccountStatus>
              </Stack>
            </Stack>
            {value ? (
              <Button destructive={true} primary onClick={onDelete}>
                Disconnect
              </Button>
            ) : (
              <Button primary onClick={modalChange}>
                Connect
              </Button>
            )}
          </Stack>
          <div className="integration-description">
            Connect to add a Smartrr widget to your Gorgias account and manage your customers&apos; subscriptions
            within tickets.
          </div>
          <div className="integration-attributes">
            <Badge>CX</Badge>
          </div>
          <div className="integration-more-info">
            <a
              href="https://help.smartrr.com/docs/support/integrations/gorgias-x-smartrr-integration"
              target="_blank"
              rel="noreferrer"
            >
              More Info
            </a>
          </div>
        </Stack>
      </CardInner>
    </ManualIntegrationCard>
  );
}
