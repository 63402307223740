import { Stack } from "@mui/material";
import { Badge, Button, FormLayout, Modal, Text, TextField } from "@shopify/polaris";
import React, { useCallback, useState } from "react";

import { IntegrationBrands, IntegrationLogo } from "./integrationLogos";
import { AccountStatus, CardInner, ManualIntegrationCard, ModalWrapper } from "./styles";
import { IntegrationSectionProps } from "./types";

export function Postscript({
  onDelete,
  onChange,
  onSave,
  loading,
  value,
  savedValue,
}: IntegrationSectionProps): JSX.Element {
  const [active, setActive] = useState(true);
  const modalChange = useCallback(() => setActive(!active), [active]);

  return (
    <ManualIntegrationCard>
      <ModalWrapper>
        <Modal
          open={!active}
          onClose={modalChange}
          title="Add Postscript API key"
          primaryAction={{
            content: "Confirm",
            loading,
            onAction() {
              onSave();
              modalChange();
            },
          }}
          secondaryActions={[
            {
              content: savedValue?.apiKey ? "Delete" : "Cancel",
              loading,
              onAction: savedValue?.apiKey
                ? () => {
                    onDelete();
                    modalChange();
                  }
                : modalChange,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  autoComplete="off"
                  label="API key"
                  value={value?.apiKey}
                  onChange={val => onChange(val)}
                />
              </FormLayout.Group>
            </FormLayout>
          </Modal.Section>
        </Modal>
      </ModalWrapper>
      <CardInner>
        <Stack direction="column" spacing="10px">
          <Stack direction="row" justifyContent="space-between" alignItems="self-start">
            <Stack direction="row" spacing="10px">
              <div className="integration-logo" style={{ padding: "6px" }}>
                <IntegrationLogo brand={IntegrationBrands.Postscript} />
              </div>
              <Stack direction="column" spacing="3px">
                <Text variant="headingMd" as="h2">
                  Postscript
                </Text>
                {savedValue?.apiKey ? (
                  <AccountStatus>Account connected</AccountStatus>
                ) : (
                  <AccountStatus>No account connected</AccountStatus>
                )}
              </Stack>
            </Stack>
            {savedValue?.apiKey ? (
              <Button onClick={modalChange}>Disconnect</Button>
            ) : (
              <Button primary onClick={modalChange}>
                Connect
              </Button>
            )}
          </Stack>
          <div className="integration-description">
            Connect to set up automations in Postscript using Smartrr subscription triggers.
          </div>
          <div className="integration-attributes">
            <Badge>SMS</Badge>
          </div>
          <div className="integration-more-info">
            <a
              href="https://help.smartrr.com/docs/support/integrations/postscript-x-smartrr-integration"
              target="_blank"
              rel="noreferrer"
            >
              More Info
            </a>
          </div>
        </Stack>
      </CardInner>
    </ManualIntegrationCard>
  );
}
