import { Box } from "@mui/material";
import styled from "styled-components";

import { colors } from "@vendor-app/constants/colors";

export const AccountStatus = styled.p`
  color: ${colors.helpText};
  margin-top: -25px;
`;
export const ModalWrapper = styled.div`
  & > .Polaris-Modal-Dialog__Modal {
    margin-left: 24rem;
  }
`;
export const CardInner = styled.div`
  & .integration-more-info a {
    text-decoration: underline;
  }
  .Polaris-Button--removeUnderline:hover {
    text-decoration: none;
  }

  & .integration-logo {
    align-items: center;
    border: 1px solid #c9cccf;
    border-radius: 40px;
    display: flex;
    height: 40px;
    justify-content: center;
    max-height: 40px;
    max-width: 40px;
    width: 40px;
  }
  &#partner-integration-peel svg {
    border-radius: 40px !important;
  }
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  & button.Polaris-Button {
    margin-bottom: 2rem;
    max-width: max-content;
  }
`;

export const ManualIntegrationCard = styled(Box).attrs(() => ({
  className: "Polaris-LegacyCard",
}))`
  min-height: 193px;
  padding: 20px;

  .integration-more-info a {
    font-variant-caps: all-small-caps;
  }
  .integration-attributes :not(:first-child) {
    margin-left: 10px;
  }
`;
