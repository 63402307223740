import { EmptyState, Layout, LegacyCard, Page, VerticalStack } from "@shopify/polaris";
import { noawait } from "@smartrr/shared/utils/noawait";
import { useEffect } from "react";
import styled from "styled-components";

import { ApiTokenCard } from "./libs/components/ApiTokenCard";
import { SmartrrApiStoreAccess, useSmartrrAPITokenModal } from "./libs/store";
import { SmartrrApiTokenModal } from "./SmartrrApiTokenModal";

const PageWrapper = styled.div`
  & .Polaris-Page-Header,
  & .Polaris-Page {
    margin-bottom: 20px;
    max-width: 612px;
    padding: 0px;
  }
  & .Polaris-FormLayout__Title.Page-Title {
    margin-bottom: 20px;
    padding: 0;
  }

  & .Polaris-Empty-State .Polaris-LegacyCard:first-child {
    display: flex;
    flex-direction: column;
    height: 336px;
    justify-content: center;
    padding: 0 !important ;
  }
`;

export function SmartrrApiKeys(): JSX.Element {
  const { openModal, isModalOpen, modalPayload, closeModal } = useSmartrrAPITokenModal();
  const { useActions, useTokenData } = SmartrrApiStoreAccess;
  const { fetchTokenData } = useActions();
  const apiTokensData = useTokenData();

  useEffect(() => {
    noawait(async () => await fetchTokenData());
  }, []);

  return (
    <PageWrapper>
      <Page
        title="API Keys"
        {...(apiTokensData.length !== 0 && {
          primaryAction: {
            content: "Create key",
            onAction() {
              openModal({});
            },
          },
        })}
      >
        {apiTokensData.length === 0 ? (
          <div className="Polaris-Empty-State">
            <LegacyCard>
              <EmptyState
                heading="You don&#39;t have any API keys yet"
                image=""
                action={{
                  content: "Create key",
                  onAction() {
                    openModal({});
                  },
                }}
              >
                <p>Want to make one?</p>
              </EmptyState>
            </LegacyCard>
          </div>
        ) : (
          <Layout>
            <Layout.Section>
              <VerticalStack gap="5">
                {apiTokensData.map(tokenData => (
                  <ApiTokenCard data={tokenData} key={tokenData.id} />
                ))}
              </VerticalStack>
            </Layout.Section>
          </Layout>
        )}
        {/* needs to be mounted and unmounted, otherwise form state gets screwy */}
        {!!isModalOpen && (
          <SmartrrApiTokenModal onClose={() => closeModal()} existingToken={modalPayload?.existingTokenData} />
        )}
      </Page>
    </PageWrapper>
  );
}
