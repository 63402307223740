import { IOrganization } from "./Organization";
import { ICreateEntity, ISharedEntityFields } from "./shared/SharedEntityFields";

export interface IOrganizationIntegration extends ISharedEntityFields {
  integrationId: `${OrganizationIntegrationId}` | OrganizationIntegrationId;
  apiKey: string;
  secretApiKey?: string; // some integrations (e.g. Klaviyo) may have a "private" API
  organizationIntegrationProperties?: OrganizationIntegrationProperties;
  organization: IOrganization;
}

export interface PostscriptIntegrationProperties {
  shopPostscriptId: string;
  postscriptWebhookOptInSubscriptionId: string;
  postscriptWebhookOptOutSubscriptionId: string;
  postscriptWebhookMessageReceivedSubscriptionId: string;
  upcomingOrderTriggerId: string;
  subscriptionCreatedTriggerId: string;
  failedPaymentTriggerId: string;
  paymentUpdateTriggerId: string;
  skippedOrderTriggerId: string;
  customerDeactivatedTriggerId: string;
}

export interface GorgiasIntegrationProperties {
  nonce: string;
  widgetId: string;
  integrationId: string;
  refreshToken: string;
  domain: string;
}
export interface KlaviyoIntegrationProperties {
  v2EventsOnly: boolean;
}

export interface OrganizationIntegrationProperties {
  postscript?: PostscriptIntegrationProperties;
  klaviyo?: KlaviyoIntegrationProperties;
  gorgias?: GorgiasIntegrationProperties;
}

export type IOrganizationIntegrationCreate = ICreateEntity<IOrganizationIntegration>;

export enum OrganizationIntegrationId {
  KLAVIYO = "klaviyo",
  LOYALTY_LION = "loyalty_lion",
  RECHARGE = "recharge",
  POSTSCRIPT = "postscript",
  ATTENTIVE = "attentive",
  SHOPIFY_FLOW = "shopify_flow",
  ARPU = "arpu",
  GORGIAS = "gorgias",
}

export interface OrganizationIntegrationKeyProperties {
  apiKey: string;
  secretApiKey?: string;
}
