import { Grid, Stack } from "@mui/material";
import { Spinner } from "@shopify/polaris";
import {
  OrganizationIntegrationId,
  OrganizationIntegrationKeyProperties,
} from "@smartrr/shared/entities/Integration";
import React, { useCallback, useEffect, useState } from "react";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { useToast } from "../../../../../_sharedComponents/Toast/ToastProvider";
import { loadIntegrations } from "../../../../../_state/actionCreators/organization";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "../../../../../_state/typedVendorReduxHooks";
import { ReCharge } from "../../../integrations/ReCharge";
import { formatApiKeys } from "../IntegrationsMarketplace/utils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Migrations(props: {}): JSX.Element {
  const dispatch = useSmartrrVendorDispatch();
  // const isSuperUser = useIsSuperUserSelector();
  const isLoadingIntegrations = useSmartrrVendorSelector(
    state => state.vendorOrganizations.isLoadingIntegrations
  );

  const activeOrganizationIntegrations = useSmartrrVendorSelector(
    state => state.vendorOrganizations.activeOrganizationIntegrations
  );
  const { addToast } = useToast();
  const [apiKeys, setApiKeys] = useState<{
    [key in OrganizationIntegrationId]?: OrganizationIntegrationKeyProperties;
  }>({});
  const [loadingIntegrations, setLoadingIntegrations] = useState<{
    [key in OrganizationIntegrationId]?: boolean;
  }>({});

  useEffect(() => {
    dispatch(loadIntegrations());
  }, []);

  useEffect(() => {
    if (activeOrganizationIntegrations) {
      setApiKeys(formatApiKeys(activeOrganizationIntegrations));
    }
  }, [activeOrganizationIntegrations]);

  const onSaveIntegration = useCallback(
    async (integrationId: OrganizationIntegrationId) => {
      const apiKey = apiKeys[integrationId];
      if (!apiKey) {
        return onDeleteIntegration(integrationId);
      }

      setIntegrationLoading(integrationId, true);
      const res = await typedFrontendVendorApi.putReq("/integration", {
        reqBody: {
          integrationId,
          publicApiKey: apiKey.apiKey,
          secretApiKey: "",
        },
      });
      setIntegrationLoading(integrationId, false);

      return addToast(
        res.type === "error"
          ? `Error updating ${formatIntegrationId(integrationId)} integration`
          : `${formatIntegrationId(integrationId)} integration updated`
      );
    },
    [apiKeys, setApiKeys]
  );

  const setIntegrationLoading = (integrationId: OrganizationIntegrationId, isLoading: boolean) => {
    setLoadingIntegrations(keys => ({ ...keys, [integrationId]: isLoading }));
  };

  const onDeleteIntegration = useCallback(async (integrationId: OrganizationIntegrationId) => {
    setIntegrationLoading(integrationId, true);
    const res = await typedFrontendVendorApi.deleteReq("/integration/:integrationId", {
      params: {
        integrationId,
      },
    });
    setIntegrationLoading(integrationId, false);

    if (res.type === "success") {
      setApiKeys(keys => ({ ...keys, [integrationId]: "" }));
    }

    return addToast(
      res.type === "error"
        ? `Error updating ${formatIntegrationId(integrationId)} integration`
        : `${formatIntegrationId(integrationId)} integration removed`
    );
  }, []);

  if (isLoadingIntegrations) {
    return (
      <Stack alignItems="center" justifyContent="center" height="30vh" width="100%">
        <Spinner />
      </Stack>
    );
  }

  return (
    <React.Fragment>
      <Grid item xs={6} height={"100%"}>
        <ReCharge
          value={apiKeys[OrganizationIntegrationId.RECHARGE]}
          loading={loadingIntegrations[OrganizationIntegrationId.RECHARGE]}
          onChange={value => setApiKeys(keys => ({ ...keys, recharge: { apiKey: value } }))}
          onSave={() => onSaveIntegration(OrganizationIntegrationId.RECHARGE)}
          onDelete={() => onDeleteIntegration(OrganizationIntegrationId.RECHARGE)}
        />
      </Grid>
    </React.Fragment>
  );
}

function formatIntegrationId(id: OrganizationIntegrationId): string {
  return id
    .split("_")
    .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join("");
}
