import { ICreateSmartrrApiToken, ISmartrrApiToken } from "@smartrr/shared/entities/SmartrrApiToken";
import { copyToClipboard } from "@smartrr/shared/utils/copyToClipboard";
import { create } from "zustand";

import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const useSmartrrAPITokenModal = createSmartrrModal<SmartrrAPITokenModalProps>();

interface SmartrrAPITokenModalProps {
  existingTokenData?: ISmartrrApiToken;
}
interface SmartrrAPITokenStore {
  tokenData: ISmartrrApiToken[];
  actions: {
    createToken(smartrrApiTokenConfig: ICreateSmartrrApiToken): Promise<void>;
    fetchTokenData(): Promise<void>;
    updateToken(id: string, smartrrApiTokenConfig: ICreateSmartrrApiToken): Promise<void>;
    deleteToken(id: string): Promise<void>;
    copyToken(token: string, toast: (msg: string) => void): void;
  };
}

export const useSmartrrApiTokenStore = create<SmartrrAPITokenStore>((set, get) => ({
  tokenData: [],
  actions: {
    async createToken(smartrrApiTokenConfig): Promise<void> {
      const response = await typedFrontendVendorApi.postReq("/smartrr-api-token", {
        reqBody: {
          smartrrApiTokenConfig,
        },
      });
      if (response.type === "success") {
        const prevTokenData = get().tokenData;
        set({ tokenData: prevTokenData.concat(response.body.token) });
      }
    },
    async updateToken(id, smartrrApiTokenConfig) {
      const response = await typedFrontendVendorApi.putReq("/smartrr-api-token/:tokenId", {
        params: {
          tokenId: id,
        },
        reqBody: {
          smartrrApiTokenConfig,
        },
      });
      if (response.type === "success") {
        const prevTokenData = get().tokenData;
        const updatedTokenData = prevTokenData.map(entry => {
          if (entry.id === response.body.token.id) {
            return response.body.token;
          }
          return entry;
        });
        set({ tokenData: updatedTokenData });
      }
    },
    async deleteToken(id) {
      const response = await typedFrontendVendorApi.deleteReq("/smartrr-api-token/:tokenId", {
        params: {
          tokenId: id,
        },
      });
      if (response.type === "success") {
        const prevTokenData = get().tokenData;
        set({ tokenData: prevTokenData.filter(entry => entry.id !== id) });
      }
    },
    async fetchTokenData(): Promise<void> {
      const response = await typedFrontendVendorApi.getReq("/smartrr-api-token");
      if (response.type === "success") {
        set({
          tokenData: response.body.tokens ?? undefined,
        });
      }
    },
    copyToken(token, toast) {
      copyToClipboard(token, "Key copied to clipboard", toast);
    },
  },
}));

export const SmartrrApiStoreAccess = {
  useTokenData() {
    return useSmartrrApiTokenStore(state => state.tokenData);
  },
  useActions() {
    return useSmartrrApiTokenStore(state => state.actions);
  },
};
