import { Grid, Stack } from "@mui/material";
import { Spinner } from "@shopify/polaris";
import {
  OrganizationIntegrationId,
  OrganizationIntegrationKeyProperties,
} from "@smartrr/shared/entities/Integration";
import React, { useCallback, useEffect, useState } from "react";

import { GorgiasOAuth2 } from "@vendor-app/app/AdminRoute/AdminIntegrationsRoute/integrations/Gorgias/GorgiasOAuth2";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { formatApiKeys } from "./utils";
import { useToast } from "../../../../../_sharedComponents/Toast/ToastProvider";
import { loadIntegrations } from "../../../../../_state/actionCreators/organization";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "../../../../../_state/typedVendorReduxHooks";
import { Attentive } from "../../../integrations/Attentive/Attentive";
import { Klaviyo } from "../../../integrations/Klaviyo";
import { Postscript } from "../../../integrations/Postscript";
import { ShopifyFlow } from "../../../integrations/ShopifyFlow";
import { Arpu } from "../../../integrations/Arpu";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";

export function IntegrationsMarketplace(): JSX.Element {
  const dispatch = useSmartrrVendorDispatch();

  const isLoadingIntegrations = useSmartrrVendorSelector(
    state => state.vendorOrganizations.isLoadingIntegrations
  );

  const activeOrganizationIntegrations = useSmartrrVendorSelector(
    state => state.vendorOrganizations.activeOrganizationIntegrations
  );
  const { addToast } = useToast();

  // DB Values
  const [savedApiKeys, setSavedApiKeys] = useState<{
    [key in OrganizationIntegrationId]?: OrganizationIntegrationKeyProperties;
  }>({});

  // Local Values
  const [apiKeys, setApiKeys] = useState<{
    [key in OrganizationIntegrationId]?: OrganizationIntegrationKeyProperties;
  }>({});
  const [loadingIntegrations, setLoadingIntegrations] = useState<{
    [key in OrganizationIntegrationId]?: boolean;
  }>({});

  useEffect(() => {
    dispatch(loadIntegrations());
  }, []);

  useEffect(() => {
    if (activeOrganizationIntegrations) {
      setApiKeys(formatApiKeys(activeOrganizationIntegrations));
      setSavedApiKeys(formatApiKeys(activeOrganizationIntegrations));
    }
  }, [activeOrganizationIntegrations]);

  useEffect(() => {
    if (savedApiKeys) {
      setApiKeys(savedApiKeys);
    }
  }, [savedApiKeys]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const errorToast = urlParams.get("errorToast");
    if (errorToast) {
      addToast(errorToast, true);
    }
  }, []);

  const onSaveIntegration = useCallback(
    async (integrationId: OrganizationIntegrationId) => {
      if (integrationId === OrganizationIntegrationId.SHOPIFY_FLOW) {
        apiKeys[integrationId] = { apiKey: "enabled" };
      }

      const keys = apiKeys[integrationId];

      if (!keys || (!keys.apiKey && !keys.secretApiKey)) {
        return onDeleteIntegration(integrationId);
      }

      setIntegrationLoading(integrationId, true);
      const res = await typedFrontendVendorApi.putReq("/integration", {
        reqBody: {
          integrationId,
          publicApiKey: keys.apiKey,
          secretApiKey: keys.secretApiKey,
        },
      });
      setIntegrationLoading(integrationId, false);
      if (res.type === "success") {
        setSavedApiKeys(formatApiKeys(res.body));
      }
      return addToast(
        res.type === "error"
          ? `Error updating ${formatIntegrationId(integrationId)} integration`
          : `${formatIntegrationId(integrationId)} integration updated`
      );
    },
    [apiKeys, setApiKeys]
  );

  const setIntegrationLoading = (integrationId: OrganizationIntegrationId, isLoading: boolean) => {
    setLoadingIntegrations(keys => ({ ...keys, [integrationId]: isLoading }));
  };

  const onDeleteIntegration = useCallback(async (integrationId: OrganizationIntegrationId) => {
    setIntegrationLoading(integrationId, true);
    const res = await typedFrontendVendorApi.deleteReq("/integration/:integrationId", {
      params: {
        integrationId,
      },
    });
    setIntegrationLoading(integrationId, false);

    if (res.type === "success") {
      setSavedApiKeys(keys => ({ ...keys, [integrationId]: "" }));
    }

    return addToast(
      res.type === "error"
        ? `Error updating ${formatIntegrationId(integrationId)} integration`
        : `${formatIntegrationId(integrationId)} integration removed`
    );
  }, []);

  if (isLoadingIntegrations) {
    return (
      <Stack alignItems="center" justifyContent="center" height="30vh" width="100%">
        <Spinner />
      </Stack>
    );
  }

  return (
    <React.Fragment>
      <Grid item xs={6} height={"100%"}>
        <Klaviyo
          value={apiKeys[OrganizationIntegrationId.KLAVIYO]}
          savedValue={savedApiKeys[OrganizationIntegrationId.KLAVIYO]}
          loading={loadingIntegrations[OrganizationIntegrationId.KLAVIYO]}
          onChange={(apiKey, secretApiKey) =>
            setApiKeys(keys => ({ ...keys, klaviyo: { apiKey, secretApiKey } }))
          }
          onSave={() => onSaveIntegration(OrganizationIntegrationId.KLAVIYO)}
          onDelete={() => onDeleteIntegration(OrganizationIntegrationId.KLAVIYO)}
        />
      </Grid>
      <Grid item xs={6} height={"100%"}>
        <GorgiasOAuth2
          value={apiKeys[OrganizationIntegrationId.GORGIAS]}
          loading={loadingIntegrations[OrganizationIntegrationId.GORGIAS]}
          onChange={() => {
            dispatch(loadIntegrations());
          }}
          onSave={() => onSaveIntegration(OrganizationIntegrationId.GORGIAS)}
          onDelete={() => onDeleteIntegration(OrganizationIntegrationId.GORGIAS)}
        />
      </Grid>
      <Grid item xs={6} height={"100%"}>
        <Postscript
          value={apiKeys[OrganizationIntegrationId.POSTSCRIPT]}
          savedValue={savedApiKeys[OrganizationIntegrationId.POSTSCRIPT]}
          loading={loadingIntegrations[OrganizationIntegrationId.POSTSCRIPT]}
          onChange={apiKey => setApiKeys(keys => ({ ...keys, postscript: { apiKey } }))}
          onSave={() => onSaveIntegration(OrganizationIntegrationId.POSTSCRIPT)}
          onDelete={() => onDeleteIntegration(OrganizationIntegrationId.POSTSCRIPT)}
        />
      </Grid>
      <Grid item xs={6} height={"100%"}>
        <Attentive
          value={apiKeys[OrganizationIntegrationId.ATTENTIVE]}
          loading={loadingIntegrations[OrganizationIntegrationId.ATTENTIVE]}
          onChange={() => {
            dispatch(loadIntegrations());
          }}
          onSave={() => onSaveIntegration(OrganizationIntegrationId.ATTENTIVE)}
          onDelete={() => onDeleteIntegration(OrganizationIntegrationId.ATTENTIVE)}
        />
      </Grid>
      <Grid item xs={6} height={"100%"}>
        <ShopifyFlow
          value={apiKeys[OrganizationIntegrationId.SHOPIFY_FLOW]}
          loading={loadingIntegrations[OrganizationIntegrationId.SHOPIFY_FLOW]}
          onChange={() => {
            dispatch(loadIntegrations());
          }}
          onSave={() => onSaveIntegration(OrganizationIntegrationId.SHOPIFY_FLOW)}
          onDelete={() => onDeleteIntegration(OrganizationIntegrationId.SHOPIFY_FLOW)}
        />
      </Grid>
      <Grid item xs={6} height={"100%"}>
        <Arpu
          value={apiKeys[OrganizationIntegrationId.ARPU]}
          onChange={NO_OP_CALLBACK}
          onSave={NO_OP_CALLBACK}
          onDelete={NO_OP_CALLBACK}
        />
      </Grid>
    </React.Fragment>
  );
}

function formatIntegrationId(id: OrganizationIntegrationId): string {
  return id
    .split("_")
    .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join("");
}
