import { Stack } from "@mui/material";
import { Button, Icon, Layout, LegacyCard, Tabs, Page } from "@shopify/polaris";
import { LockMajor } from "@shopify/polaris-icons";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import React, { useMemo, useState } from "react";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import * as lockedThumbnail from "./views/assets/locked-thumbnail.png";
import { IntegrationsHub } from "./views/IntegrationHub";
import { SmartrrApiKeys } from "./views/SmartrrApiKeys";
import { Webhooks } from "./views/Webhooks";
import { isFeatureAvailable } from "../components/authorization/featureAccess";

enum IntegrationsTab {
  INTEGRATIONS_HUB,
  APIKEYS,
  WEBHOOKS,
}

export function AdminIntegrationsRoute(): JSX.Element {
  const [selectedTab, setSelectedTab] = useState<IntegrationsTab>(IntegrationsTab.INTEGRATIONS_HUB);
  const isSuperUser = useSmartrrVendorSelector(state => state.auth.user?.isSuperUser);

  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const user = useSmartrrVendorSelector(state => state.auth.user);
  const isIntegrationsAvailable = isFeatureAvailable(FeatureEnum.INTEGRATIONS, user, activePlan);

  const tabs = useMemo(
    () => [
      { id: "marketplace", content: "Integrations" },
      { id: "apikeys", content: "API Keys" },
      { id: "webhooks", content: "Webhooks" },
    ],
    [isSuperUser]
  );

  const renderSwitch = (selectedTab: IntegrationsTab) => {
    switch (selectedTab) {
      case IntegrationsTab.APIKEYS: {
        return <SmartrrApiKeys />;
      }
      case IntegrationsTab.INTEGRATIONS_HUB: {
        return <IntegrationsHub />;
      }
      case IntegrationsTab.WEBHOOKS: {
        return <Webhooks />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <React.Fragment>
      <Page>
        <Layout>
          <Layout.Section>
            <Tabs
              tabs={tabs}
              selected={isIntegrationsAvailable ? selectedTab : -1}
              onSelect={isIntegrationsAvailable ? setSelectedTab : () => null}
              fitted
            >
              {isIntegrationsAvailable ? <div style={{ paddingTop: 30 }}>{renderSwitch(selectedTab)}</div> : null}
            </Tabs>
            {isIntegrationsAvailable ? null : (
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  paddingTop: "20px",
                  "& a": {
                    width: "max-content",
                  },
                  "& p": {
                    fontSize: "16px",
                  },
                  "& h2": {
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "24px",
                  },
                }}
              >
                <LegacyCard>
                  <Stack direction="row" alignItems="center" justifyContent="center" overflow="hidden">
                    <img
                      src={lockedThumbnail.default}
                      style={{ maxWidth: "232px", height: "auto", marginLeft: "-3px" }}
                    />
                    <Stack direction="column" spacing="20px" width="407px" padding="20px">
                      <Stack direction="row" spacing="10px">
                        <Icon source={LockMajor} color="base" />
                        <h2>Integrations unavailable on your current plan</h2>
                      </Stack>
                      <p>
                        Upgrade to Grow to acess our integrations with Klaviyo, LoyaltyLion, Gorgias, Postscript,
                        &amp; more. You&apos;ll also be able to create Smartrr API keys and set up webhooks.
                      </p>
                      <Button primary url="/admin/smartrr-account">
                        📈 Upgrade to Grow
                      </Button>
                    </Stack>
                  </Stack>
                </LegacyCard>
              </Stack>
            )}
          </Layout.Section>
        </Layout>
      </Page>
    </React.Fragment>
  );
}
