import { Grid } from "@mui/material";
import { FooterHelp, Icon, Link, Page } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";
import React from "react";
import styled from "styled-components";

import { IntegrationsMarketplace } from "./IntegrationsMarketplace";
import { Migrations } from "./migrations";
import { PartnerIntegrations } from "./partnerIntegrations";

const PageWrapper = styled.div`
  & .Polaris-Page-Header,
  & .Polaris-Page {
    padding: 0;
    & h1 {
      margin-bottom: 20px;
    }
  }

  & .Polaris-FormLayout__Title.Page-Title {
    margin-bottom: 30px;
    padding: 0;
  }
  & .Polaris-FooterHelp {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%);
    & .Polaris-FooterHelp__Text {
      display: flex;
      justify-content: center;
      font-size: 14px;
      & span {
        margin-right: 8px;
        & .Polaris-Icon__Svg {
          fill: #00a0ac;
        }
      }
    }
    & a {
      color: #2c6ecb;
    }
  }
`;

export function IntegrationsHub() {
  return (
    <PageWrapper>
      <Page title="Integrations">
        <Grid container spacing="20px" sx={{ marginBottom: "20px" }}>
          <IntegrationsMarketplace />
        </Grid>
        <div className="Polaris-Page-Header__TitleWrapper">
          <h1 className="Polaris-Header-Title">Partner Integrations</h1>
        </div>
        <PartnerIntegrations />
        <div className="Polaris-Page-Header__TitleWrapper">
          <h1 className="Polaris-Header-Title">Migrations</h1>
        </div>
        <Grid container spacing="20px">
          <Migrations />
        </Grid>
        <FooterHelp>
          <Icon source={InfoMinor} color="base" />
          Learn more about<div>&nbsp;</div>
          <Link
            external={true}
            url="https://help.smartrr.com/docs/support/integrations/what-integrations-does-smartrr-offer"
          >
            Integrations
          </Link>
        </FooterHelp>
      </Page>
    </PageWrapper>
  );
}
