import { EmptyState, FooterHelp, Icon, LegacyCard, LegacyStack, Link, Page, Spinner } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";
import { IWebhook, IWebhookCreate, WebhookEventType } from "@smartrr/shared/entities/Webhook";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { AllWebhooks, ExistingWebhookWrapper } from "./styles";
import {
  createWebhook,
  deleteWebhook,
  loadWebhooks,
  updateWebhook,
} from "../../../../_state/actionCreators/webhooks";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "../../../../_state/typedVendorReduxHooks";
import { Webhook, WebhookEventTypes } from "../../../components/elements/Webhook";

const PageWrapper = styled.div`
  & .Polaris-Page-Header,
  & .Polaris-Page {
    padding: 0px;
    margin-bottom: 20px;
    max-width: 612px;
  }
  & .Polaris-FormLayout__Title.Page-Title {
    margin-bottom: 20px;
    padding: 0;
  }
  & .Polaris-FooterHelp {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%);

    & .Polaris-FooterHelp__Text {
      display: flex;
      font-size: 14px;
      & span {
        margin-right: 8px;
        & .Polaris-Icon__Svg {
          fill: #00a0ac;
        }
      }
    }
    & a {
      color: #2c6ecb;
    }
  }
`;

export function Webhooks(): JSX.Element {
  const [selectedEventType, setSelectedEventType] = useState(WebhookEventTypes[0].value);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [isWebhookLoading, setisWebhookLoading] = useState(true);

  const dispatch = useSmartrrVendorDispatch();
  useEffect(() => {
    const loadWebhooksAction = async () => {
      await dispatch(loadWebhooks());
      setisWebhookLoading(false);
    };
    loadWebhooksAction();
  }, []);
  const webhooks = useSmartrrVendorSelector(state => state.webhooks.webhooks);

  const resetNewWebhookFields = () => {
    setSelectedEventType(WebhookEventTypes[0].value);
    setSelectedUrl("");
    setisWebhookLoading(false);
  };

  const addNewWebhook = useCallback(async () => {
    setisWebhookLoading(true);
    const newWebhook = {
      eventType: selectedEventType as WebhookEventType,
      url: selectedUrl,
    };
    await dispatch(createWebhook(newWebhook as IWebhookCreate));
    await dispatch(loadWebhooks());
    setisWebhookLoading(false);
    resetNewWebhookFields();
  }, [selectedEventType, selectedUrl]);

  const updateWebhookAction = useCallback(
    async (webhook: IWebhook) => {
      setisWebhookLoading(true);
      await dispatch(updateWebhook(webhook.id, webhook));
      await dispatch(loadWebhooks());
      setisWebhookLoading(false);
    },
    [webhooks]
  );

  const deleteWebhookAction = useCallback(
    async (webhook: IWebhook) => {
      setisWebhookLoading(true);
      await dispatch(deleteWebhook(webhook.id));
      await dispatch(loadWebhooks());
      setisWebhookLoading(false);
    },
    [webhooks]
  );

  if (isWebhookLoading) {
    return (
      <LegacyStack alignment="center" distribution="center">
        <Spinner />
      </LegacyStack>
    );
  }

  return (
    <PageWrapper>
      {webhooks.length == 0 ? (
        <LegacyCard>
          <EmptyState
            heading="You don&#39;t have any webhooks"
            image=""
            action={{
              content: "Add Webhook",
              onAction() {
                addNewWebhook();
              },
            }}
          >
            <p>Want to add one?</p>
          </EmptyState>
        </LegacyCard>
      ) : (
        <Page
          title="Webhooks"
          primaryAction={{
            content: "Add Webhook",
            onAction() {
              addNewWebhook();
            },
          }}
        >
          <AllWebhooks>
            {Array.isArray(webhooks) &&
              webhooks.map(webhook => (
                <ExistingWebhookWrapper className="webhook-wrapper" key={webhook.id}>
                  <Webhook
                    webhook={webhook}
                    updateWebhook={updateWebhookAction}
                    deleteWebhook={deleteWebhookAction}
                  />
                </ExistingWebhookWrapper>
              ))}
          </AllWebhooks>
        </Page>
      )}
      <FooterHelp>
        <Icon source={InfoMinor} color="base" />
        Learn more about<div>&nbsp;</div>
        <Link
          external={true}
          url="https://help.smartrr.com/docs/support/integrations/how-can-i-use-webhooks-with-smartrr"
        >
          Webhooks
        </Link>
      </FooterHelp>
    </PageWrapper>
  );
}
