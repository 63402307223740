import { Stack } from "@mui/material";
import { Badge, Button, Text } from "@shopify/polaris";
import React from "react";

import { IntegrationBrands, IntegrationLogo } from "./integrationLogos";
import { AccountStatus, CardInner, ManualIntegrationCard } from "./styles";
import { IntegrationSectionProps } from "./types";

export function Arpu({ value }: IntegrationSectionProps): JSX.Element {
  return (
    <ManualIntegrationCard>
      <CardInner>
        <CardInner>
          <Stack direction="column" spacing="10px">
            <Stack direction="row" justifyContent="space-between" alignItems="self-start">
              <Stack direction="row" spacing="10px">
                <div className="integration-logo">
                  <IntegrationLogo brand={IntegrationBrands.Arpu} />
                </div>
                <Stack direction="column" spacing="3px">
                  <Text variant="headingMd" as="h2">
                    Arpu
                  </Text>
                  {value ? (
                    <AccountStatus>Account connected</AccountStatus>
                  ) : (
                    <AccountStatus>No account connected</AccountStatus>
                  )}
                </Stack>
              </Stack>
              <Button primary onClick={() => window.location.assign("https://www.getarpu.com/")}>
                Visit site
              </Button>
            </Stack>
            <div className="integration-description">
              Offer easy, one-click subscription management via email without requiring customers to log in to
              their account.
            </div>
            <div className="integration-attributes">
              <Badge>Email</Badge>
              <Badge>Subscription management</Badge>
            </div>
            <div className="integration-more-info">
              <a
                target="_blank"
                href="https://help.getarpu.com/help/smartrr-integration-how-to-connect-and-configure"
                rel="noreferrer"
              >
                More Info
              </a>
            </div>
          </Stack>
        </CardInner>
      </CardInner>
    </ManualIntegrationCard>
  );
}
