import {
  IOrganizationIntegration,
  OrganizationIntegrationId,
  OrganizationIntegrationKeyProperties,
} from "@smartrr/shared/entities/Integration";

export function formatApiKeys(integrations: IOrganizationIntegration[]): {
  [key in OrganizationIntegrationId]?: OrganizationIntegrationKeyProperties;
} {
  if (!integrations) {
    return {};
  }

  return integrations.reduce((acc, integration) => {
    acc[integration.integrationId] = { apiKey: integration.apiKey, secretApiKey: integration.secretApiKey };
    return acc;
  }, {} as { [key in OrganizationIntegrationId]: OrganizationIntegrationKeyProperties });
}
