import styled from "styled-components";

export const ExistingWebhookWrapper = styled.div`
  position: relative;
`;

export const WebhookHeaderWrapper = styled.div`
  margin: 1rem;
  padding-left: 1.5rem;
`;

export const NewWebhookContainer = styled.div`
  margin: 1rem;
  margin-top: 2.5rem;
  padding-left: 1.5rem;
`;

export const NewWebhookButton = styled.div`
  margin: 1rem;
  padding-left: 1.5rem;
`;

export const AllWebhooks = styled.div`
  & .webhook-wrapper {
    :not(:first-child) {
      padding-top: 20px;
    }
  }
`;
