type ValidAttr =
  | "Email"
  | "SMS"
  | "CX"
  | "Loyalty"
  | "Rewards"
  | "Database"
  | "Messaging"
  | "Alerts"
  | "Ecommerce"
  | "Tagging"
  | "Workflows"
  | "Retention"
  | "Payment notifications"
  | "Analytics"
  | "Data"
  | "3PL"
  | "Logistics"
  | "Inventory management"
  | "Subscription management";

export interface IntegrationAttributes {
  [key: string]: {
    description: string;
    attr: ValidAttr[];
    moreInfoUrl: string;
    visitSiteUrl?: string;
  };
}

export const partnerIntegrations: IntegrationAttributes[] = [
  {
    Churnbuster: {
      description:
        "Reduce customer churn and improve subscriber experience when subscription payments are missed.",
      attr: ["Retention", "Payment notifications"],
      moreInfoUrl: "https://churnbuster.io/docs/smartrr-integration",
      visitSiteUrl: "https://churnbuster.io/",
    },
  },
  {
    Cogsy: {
      description:
        "Get predictive subscription inventory information, set aside stock for subscribers, and set low-inventory alerts. ",
      attr: ["Inventory management", "Analytics"],
      moreInfoUrl: "https://cogsy.com/dtc/subscriptions-feature/",
      visitSiteUrl: "https://cogsy.com/",
    },
  },
  {
    Littledata: {
      description: "Scale faster with complete sales and marketing data about your members and subscribers.",
      attr: ["Analytics", "Data"],
      moreInfoUrl: "https://www.littledata.io/connections/smartrr",
      visitSiteUrl: "https://www.littledata.io/",
    },
  },
  {
    Peel: {
      description:
        "Integrate with a suite of 41 metrics across product and Smartrr segments specific to subscription businesses.",
      attr: ["Analytics", "Data"],
      moreInfoUrl: "https://help.peelinsights.com/docs/smartrr",
      visitSiteUrl: "https://www.peelinsights.com/",
    },
  },
  {
    Shipbob: {
      description: "Offer best-in-class supply chain solutions for your growing subscription business.",
      attr: ["Logistics", "3PL"],
      moreInfoUrl: "https://www.shipbob.com/partners/smartrr/",
      visitSiteUrl: "https://www.shipbob.com/",
    },
  },
  {
    Rebuy: {
      description: "Unlocks the power of intelligent cross-selling for your subscription products.",
      attr: ["SMS"],
      moreInfoUrl: "https://partners.smartrr.com/rebuy",
      visitSiteUrl: "https://www.rebuyengine.com/",
    },
  },
];
