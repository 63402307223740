import { Grid, Stack } from "@mui/material";
import { Badge, Button, Text } from "@shopify/polaris";
import { sortByKey } from "@smartrr/shared/utils/sortBy";
import React from "react";

import { partnerIntegrations } from "./constants";
import { IntegrationBrands, IntegrationLogo } from "../../../integrations/integrationLogos";
import { AccountStatus, CardInner, ManualIntegrationCard } from "../../../integrations/styles";

export const PartnerIntegrations = () => {
  return (
    <Grid container spacing="20px" sx={{ marginBottom: "20px" }}>
      {sortByKey(partnerIntegrations).map((integration, i) => {
        const [name, data] = Object.entries(integration)[0];
        const { attr, description, moreInfoUrl, visitSiteUrl } = data;

        const integrationLogo =
          IntegrationBrands[
            Object.values(IntegrationBrands).find(
              x => x.toString().toLowerCase() === name.toLowerCase()
            )! as keyof typeof IntegrationBrands
          ];

        return (
          <Grid key={i} item xs={6} height={"100%"}>
            <ManualIntegrationCard>
              <CardInner id={`partner-integration-${name.toLowerCase()}`}>
                <Stack direction="column" spacing="10px">
                  <Stack direction="row" justifyContent="space-between" alignItems="self-start">
                    <Stack direction="row" spacing="10px">
                      <div className="integration-logo">
                        <IntegrationLogo brand={integrationLogo} />
                      </div>
                      <Stack direction="column" spacing="3px">
                        <Text variant="headingMd" as="h2">
                          {name}
                        </Text>
                        <AccountStatus>Connect on {name}</AccountStatus>
                      </Stack>
                    </Stack>

                    <Button primary url={visitSiteUrl} external removeUnderline>
                      Visit site
                    </Button>
                  </Stack>
                  <div className="integration-description">{description}</div>
                  <div className="integration-attributes">
                    {attr.map((x, i) => (
                      <Badge key={i}>{x}</Badge>
                    ))}
                  </div>
                  <div className="integration-more-info">
                    <a href={moreInfoUrl} target="_blank" rel="noreferrer">
                      More Info
                    </a>
                  </div>
                </Stack>
              </CardInner>
            </ManualIntegrationCard>
          </Grid>
        );
      })}
    </Grid>
  );
};
